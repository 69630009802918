
import { arrayOf, nullable, oneOfType, shape, string } from 'vue-types';
import { entryShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    entries: arrayOf(shape(entryShape).loose),
  },
};
