
import { arrayOf, nullable, oneOf, oneOfType, shape, string } from 'vue-types';
import { entryShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    entries: arrayOf(shape(entryShape).loose),
    edge: oneOf(['tl', 'tr', 'br', 'bl']).def('br'),
  },
  computed: {
    single() {
      return this.entries.length === 1;
    },
  },
};
